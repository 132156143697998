import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

require("es6-promise").polyfill()

export default function HomepagePagePage({ pageContext: { getContentDataRows }, props }) {

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sitemap</title>
      </Helmet>

      <div className="home">
        <section className="app-content">
          {console.log(getContentDataRows)}
        </section>
      </div>
    </Layout>
  )
}
